import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidebarMenu from '../SidebarMenu'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { updatePassword } from '../../../actions/auth';


Modal.setAppElement("#root"); // Ensure this is called only once (outside the component)

const Settings = ({ auth: { user, loading }, updatePassword }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [loading2, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    // Open Modal
    const openModal = (e) => {
        e.preventDefault(); // Prevent unnecessary re-renders caused by `<a>` tag default behavior
        setIsOpen(true);
    };

    // Close Modal
    const closeModal = () => {
        setIsOpen(false);
        reset(); // Reset form fields on close
    };

    // Form Submit Handler
    const onSubmitPassword = (data) => {
        console.log(data.oldPassword);
        setLoading(true);
        try {
            updatePassword(data.oldPassword, data.newPassword, data.confirmPassword);
            closeModal();
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };



    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobNumber: '',
        password: ''
    });

    const { firstName, lastName, email, mobNumber, password } = formData;

    useEffect(() => {
        setFormData({
            firstName: loading || user.firstName === null ? '' : user.firstName,
            lastName: loading || user.lastName === null ? '' : user.lastName,
            email: loading || user.email === null ? '' : user.email,
            mobNumber: loading || user.mobNumber === null ? '' : user.mobNumber,
            password: loading || user.password === null ? '' : user.password

        });
    }, [loading, user]);

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        e.preventDefault();
    }



    return (
        <Fragment>
            <section id="contentarea4" className='vh-100'>
                <div className="container">
                    <div className="row">
                        <SidebarMenu />
                        <div className="col-md-9">
                            <div className="card shadow">
                                <h3 className="fw-bolder p-3 pt-4">Account Settings</h3>
                                <hr />
                                <h5 className="px-3 mt-3">Basic Details</h5>
                                <form method="POST" className="px-3" onSubmit={e => onSubmit(e)}>
                                    <div className="row my-3">
                                        <div className="col-6">
                                            <label className="label">First name <sup className="text-danger">*</sup></label>
                                            <input
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="First name"
                                                name="firstName"
                                                value={firstName} onChange={e => onChange(e)}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label className="label">Last name <sup className="text-danger">*</sup></label>
                                            <input
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="Last name"
                                                name="lastName"
                                                value={lastName} onChange={e => onChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-12">
                                            <label className="label">Email ID <sup className="text-danger">*</sup></label>
                                            <input
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="Email ID"
                                                name="email"
                                                value={email} onChange={e => onChange(e)}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-12">
                                            <label className="label">Phone <sup className="text-danger">*</sup></label>
                                            <input
                                                className="form-control mt-2"
                                                placeholder="Phone Number"
                                                type="text"
                                                name="mobNumber"
                                                value={mobNumber} onChange={e => onChange(e)}
                                            />
                                        </div>
                                    </div>
                                </form>
                                <hr />
                                <div className="row p-4 mb-4">
                                    <div className="col-6">
                                        <h5>Password</h5>
                                    </div>
                                    <div className="col-6">
                                        <h5>  <Link href="#" className="blulink" onClick={openModal}>
                                            {password !== '' ? "Change Password" : "Set Password"}
                                        </Link> </h5>
                                        <Modal
                                            isOpen={isOpen}
                                            onRequestClose={closeModal}
                                            contentLabel={password ? "Change Password" : "Set Password"}
                                            className="modal-content"
                                            overlayClassName="modal-overlay"
                                        >
                                            <div className="modal-header">
                                                <h2>{password !== '' ? "Change Password" : "Set Password"}</h2>
                                                <button className="close-button" onClick={closeModal}>×</button>
                                            </div>

                                            <form onSubmit={handleSubmit(onSubmitPassword)} className="modal-body">
                                                {password !== '' && (
                                                    <div className="form-group">
                                                        <label>Old Password</label>
                                                        <input
                                                            type="password" className="form-control mt-2"
                                                            {...register("oldPassword", { required: "Old password is required" })}
                                                            placeholder="Enter old password"
                                                        />
                                                        {errors.oldPassword && <p className="error-text">{errors.oldPassword.message}</p>}
                                                    </div>
                                                )}

                                                <div className="form-group">
                                                    <label>New Password</label>
                                                    <input className="form-control mt-2"
                                                        type="password"
                                                        {...register("newPassword", {
                                                            required: "New password is required",
                                                            minLength: { value: 6, message: "Password must be at least 6 characters" }
                                                        })}
                                                        placeholder="Enter new password"
                                                    />
                                                    {errors.newPassword && <p className="error-text">{errors.newPassword.message}</p>}
                                                </div>

                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <input
                                                        type="password" className="form-control mt-2"
                                                        {...register("confirmPassword", {
                                                            required: "Confirm password is required",
                                                            validate: (value) =>
                                                                value === watch("newPassword") || "Passwords do not match",
                                                        })}
                                                        placeholder="Confirm new password"
                                                    />
                                                    {errors.confirmPassword && <p className="error-text">{errors.confirmPassword.message}</p>}
                                                </div>

                                                <div className="modal-footer">
                                                    <button type="button" className="btn cancel-btn" onClick={closeModal}>Cancel</button>
                                                    <button type="submit" className="btn submit-btn" disabled={loading2}>
                                                        {password !== '' ? "Change Password" : "Set Password"}
                                                    </button>
                                                </div>
                                            </form>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

Settings.propTypes = {
    auth: PropTypes.object.isRequired,
    updatePassword: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,

});

export default connect(mapStateToProps, { updatePassword })(Settings)

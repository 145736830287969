export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'CUSTOMER_REGISTER_SUCCESS';
export const REGISTER_FAIL = 'CUSTOMER_REGISTER_FAIL';
export const USER_LOADED = 'CUSTOMER_LOADED';
export const AUTH_ERROR = 'CUSTOMER_AUTH_ERROR';
export const LOGIN_SUCCESS = 'CUSTOMER_LOGIN_SUCCESS';
export const LOGIN_FAIL = 'CUSTOMER_LOGIN_FAIL';
export const LOGOUT = 'CUSTOMER_LOGOUT';
export const VERIFY_EMAIL = 'CUSTOMER_VERIFY_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

//CATEGORIES
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';

//SERVICES
export const GET_SERVICES = 'GET_SERVICES';
export const ADD_SERVICE = 'ADD_SERVICE';
export const SERVICE_ERROR = 'SERVICE_ERROR';




//CATEGORY WISE SERVICES
export const GET_CLIENT_WISE_SERVICES = 'GET_SERVICES';
export const CLIENT_WISE_SERVICE_ERROR = 'SERVICES_ERROR';


//CATEGORY WISE INFO
export const GET_CLIENT_WISE_INFO = 'GET_CLIENT_INFO';
export const CLIENT_WISE_INFO_ERROR = 'CLIENT_INFO_ERROR';

//WEEK DAYS
export const UPDATE_WEEK_DAYS = 'UPDATE_WEEK_DAYS';
export const GET_WEEK_DAYS = 'GET_WEEK_DAYS';
export const WEEK_DAY_ERROR = 'WEEK_DAY_ERROR';


//INTERVALS 
export const GET_INTERVAL_TYPES = 'GET_INTERVAL_TYPES';
export const INTERVAL_ERROR = 'INTERVAL_ERROR';


//PAYMENTS
export const ADD_ORDER = 'ADD_ORDER';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_ERROR = 'ORDER_ERROR';
export const ORDER_FAILURE = 'ORDER_FAILURE';

// APPOINTMENTS - UPCOMING
export const GET_UPCOMING_APPOINTMENTS = 'GET_UPCOMING_APPOINTMENTS';
export const UPCOMING_APPOINTMENTS_ERROR = 'UPCOMING_APPOINTMENTS_ERROR';
export const GET_UPCOMING_APPOINTMENT = 'GET_UPCOMING_APPOINTMENT';
export const UPDATE_UPCOMING_APPOINTMENT = 'UPDATE_UPCOMING_APPOINTMENT';


// APPOINTMENTS - PAST
export const GET_PAST_APPOINTMENTS = 'GET_PAST_APPOINTMENTS';
export const PAST_APPOINTMENTS_ERROR = 'PAST_APPOINTMENTS_ERROR';


// APPOINTMENT CANCEL 
export const GET_CANCEL_BOOKINGS = 'GET_CANCEL_BOOKINGS';
export const SUCCESS_CANCEL_BOOKING = 'SUCCESS_CANCEL_BOOKING';
export const CANCEL_BOOKING_ERROR = 'CANCEL_BOOKING_ERROR';


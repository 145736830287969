import {
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    VERIFY_EMAIL,
    REGISTER_SUCCESS,
    UPDATE_PASSWORD

} from '../actions/types';

const initialState = { token: localStorage.getItem('token'), isAuthenticated: null, loading: true, user: null }

export default function auth(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            };
        case REGISTER_SUCCESS:
            return { ...state, token: null, isAuthenticated: false, loading: false };
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return { ...state, ...payload, isAuthenticated: true, loading: false };
        case REGISTER_FAIL:
            localStorage.removeItem('token');
            return { ...state, token: null, isAuthenticated: false, loading: false };
        case UPDATE_PASSWORD:
            return { ...state, ...payload, isAuthenticated: true, loading: false };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
        case VERIFY_EMAIL:
            localStorage.removeItem('token');
            return { ...state, token: null, isAuthenticated: false, loading: false };
        default:
            return state;
    }
}
import axios from 'axios';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    GET_CLIENT_WISE_INFO,
    CLIENT_WISE_INFO_ERROR,
    UPDATE_PASSWORD
} from './types';

import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';

import { base_url } from './constants';

axios.defaults.baseURL = base_url;
//Load User
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get('/api/customers/auth/');
        dispatch({ type: USER_LOADED, payload: res.data });
    } catch (error) {
        console.log(error);
        dispatch({ type: AUTH_ERROR });
    }
}

//REGISTER USER

export const register = (businessName, { firstname, lastname, email, password, country, mobile }) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ firstname, lastname, email, password, country, mobile });

    try {
        const res = await axios.post(`/api/customers/${businessName}`, body, config);
        console.log(res);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });


        dispatch(setAlert(res.data, 'success', 20000));

    } catch (err) {
        const errors = err.response.data.errors;
        console.log(errors);
        console.log("failed");
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({ type: REGISTER_FAIL });
    }
}


//CLIENT INFO

export const getClientInfo = (bName) => async dispatch => {
    console.log(bName);
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`/api/client/${bName}`, config);

        dispatch({
            type: GET_CLIENT_WISE_INFO,
            payload: res.data
        });
    } catch (err) {
        console.log(err);
        dispatch({ type: CLIENT_WISE_INFO_ERROR });
    }
}

//LOGIN USER
export const login = ({ email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ email, password });
    try {
        const res = await axios.post('/api/customers/auth/', body, config);
        console.log(res.data)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        console.log(res.data['code']);
        if (res.data['code'] === 400) {
            dispatch(setAlert(res.data['msg'], 'danger'));
        }
        dispatch(loadUser());
    } catch (err) {
        console.log(err);
        const errors = err.response.data.errors;
        console.log(errors);
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({ type: LOGIN_FAIL });
    }
}

//LOGIN USER WITH GOOGLE
export const googleLogin = ({ businessName, email, googleID, verified_email, lastName, firstName, name, picture }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Cross-Origin-Opener-Policy': 'same-origin-allow-popups'
        }
    }
    const body = JSON.stringify({ businessName, email, googleID, verified_email, lastName, firstName, name, picture });
    console.log(body);
    try {
        const res = await axios.post('/api/customer/google_auth/', body, config);
        console.log(res.data);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());
    } catch (err) {
        console.log(err);
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({ type: LOGIN_FAIL });
    }
}
//Logout / Clear Profile
export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
}

//Update password
export const updatePassword = (oldPassword, newPassword, confirmPassword) => async dispatch => {
    try {
        const response = await axios.post("/api/customer_password", {
            body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
            headers: {
                'Content-Type': 'application/json',
                'Cross-Origin-Opener-Policy': 'same-origin-allow-popups'
            },

        });
        console.log(response)
        dispatch({
            type: UPDATE_PASSWORD,
            payload: response.data
        });
        console.log(response.data['code']);
        if (response.data['code'] === 400) {
            dispatch(setAlert(response.data['msg'], 'danger'));
        }
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }

};